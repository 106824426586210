@import "~bootstrap/dist/css/bootstrap.min.css";
@import "custom-bootstrap-design/custom-bootstrap";
@import "components/components";
@import "~font-awesome/css/font-awesome.css";
@import "variables";
@import "mixins";
@import "fonts";
@import "fontawesome/fontawesome";
@import "fontawesome/solid";
@import "fontawesome/light";
@import "fontawesome/regular";
@import "@patientus/video-lib-angular/assets/style/global.css";

*, html {
    margin: 0;
    padding: 0;
}

*:focus {
    outline: none;
}

body {
    background: #{$color-white};
    font-family: #{$font-family-roboto};

    --mainColor: #{$color-main};
    --secondaryColor: #{$color-secondary};

    --primaryColoredBtn: #{$color-main};
    --secondaryColoredBtn: #{$color-secondary};
    --hoverPrimaryColoredBtn: #{$color-dark-blue-rollover};
    --hoverSecondaryColoredBtn: #{$color-blue-rollover};

    --coloredShadow: rgba(0, 0, 0, 0.3);
    --fontColorOnSecondaryColor: #{$color-white};
    --headlineColor: #{$color-main};
    --logoBackgroundColor: #{$color-white};
}

.container {
    padding: 0 0 20px 0;
    clear: both;
    position: relative;
    background: #fff;

    @include big-screens {
        width: 960px;
        margin: 100px auto 20px auto;
    }
    @include small-screens {
        width: 100%;
        margin: 40px auto 40px auto;
    }
}

.dashboard-container {
    margin: 20px 40px;
}

.site-register {
    /* Global Designs */
    h1 {
        color: #000;
        font-weight: lighter;
    }

    p {
        line-height: 1.6em;
        color: #666;
        font-size: 14px;
        padding: 0 0 20px 0;
    }

    h2 {
        font-size: 40px;
        font-style: italic;
        font-weight: normal;
        color: #14387f;
        clear: both;
        margin: 20px 20px 0 20px;
    }

    h4 {
        font-size: 16px;
        text-transform: uppercase;
        font-weight: bold;
        color: #666;
        display: block;
        margin: 0 0 10px 0;
        line-height: 1.6em;
    }

    h2 span {
        font-size: 60px;
    }

    input[type=text], input[type=password] {
        padding: 15px;
        border: 0px solid #ccc;
        background: #ededed;
        width: 100%;
        font-size: 14px;
        margin: 0 0 20px 0;
    }

    .select_xl {
        line-height: 36px;
        padding: 8px 15px 7px 15px;
        border: 1px solid transparent;
        background: $color-lightgrey;
        width: 100%;
        margin: 0 0 20px 0;
        color: $color-placeholder-font;
        font-size: 14px;

        &.ng-untouched {
            color: $color-placeholder-font !important;
        }
    }

    .select_xl.filled {
        color: $color-black;
    }

    input[type=submit], input[type=button] {
        -webkit-appearance: none;
        background: #009fe3;
        border: 0;
        color: #fff;
        padding: 20px 40px 20px 40px;
        cursor: pointer;
    }

    input[type='submit'].inactive, input[type=button].inactive,
    input[type='submit'].ajax, input[type=button].ajax {
        cursor: default;
        filter: alpha(opacity=50);
        -moz-opacity: 0.5;
        -khtml-opacity: 0.5;
        opacity: 0.5;
    }

    .checkbox {
        margin: 0 0 20px 0;
        color: #666;
    }

    input[type=checkbox] {
    }

    label {
        line-height: 1.6em;
        display: inline;
    }

    input[type='text'],
    input[type='password'],
    select {
        border: 1px solid transparent;
        -webkit-transition: border 500ms ease-out;
        -moz-transition: border 500ms ease-out;
        -o-transition: border 500ms ease-out;
        transition: border 500ms ease-out;
        -webkit-appearance: none;
        -webkit-border-radius: 0;
    }

    input[type='text']:focus, input[type='password']:focus, select:focus, textarea:focus {
        border: 1px solid #000;
    }

    input[type='text'].validate.invalid,
    input[type='password'].validate.invalid,
    select.validate.invalid {
        border: 1px solid $color-error-font;
        border-left: 10px solid $color-error-font;
        -webkit-transition: border 500ms ease-out;
        -moz-transition: border 500ms ease-out;
        -o-transition: border 500ms ease-out;
        transition: border 500ms ease-out;
    }

    app-step-account input.ng-invalid.ng-touched,
    app-step-personal input.ng-invalid.ng-touched,
    app-step-invoice input.ng-invalid.ng-touched {
        border: 1px solid $color-error-font;
        border-left: 10px solid $color-error-font;
        -webkit-transition: border 500ms ease-out;
        -moz-transition: border 500ms ease-out;
        -o-transition: border 500ms ease-out;
        transition: border 500ms ease-out;
    }

    input[type='text'].validate.valid,
    input[type='password'].validate.valid,
    select.validate.valid {
        border: 1px solid #10B571;
        border-left: 10px solid #10B571;
        -webkit-transition: border 500ms ease-out;
        -moz-transition: border 500ms ease-out;
        -o-transition: border 500ms ease-out;
        transition: border 500ms ease-out;
    }

    app-step-account input.ng-valid.ng-touched,
    app-step-personal input.ng-valid.ng-touched,
    app-step-invoice input.ng-valid.ng-touched {
        border: 1px solid #10B571;
        border-left: 10px solid #10B571;
        -webkit-transition: border 500ms ease-out;
        -moz-transition: border 500ms ease-out;
        -o-transition: border 500ms ease-out;
        transition: border 500ms ease-out;
    }

    .text-core .text-wrap {
        width: 100% !important;
        font-size: 14px;

    }

    .soft_alert {
        background: #ccc;
        color: #fff;
        width: 100%;
        padding: 0;
        display: none;
    }

    .soft_alert p {
        margin: 0;
        padding: 30px;
        text-align: center;
    }

    .data_box {
        padding: 0;
        border: 1px solid #666;
        margin: 0 0 20px 0;

        .data_box_header {
            background: #666;
            color: #fff;
            position: relative;
            height: 40px;
            overflow: hidden;
            line-height: 40px;
            padding-left: 20px;

            a {
                position: absolute;
                right: 0;
                top: 0;
                height: 40px;
                width: 40px;
                background: #fff url(assets/css/images/register/patientus_edit_icon.png) no-repeat center;
                text-indent: -9999px;

                &:hover {
                    background-color: var(--secondaryColor);
                }
            }
        }

        table {
            margin: 20px;
            padding: 0;

            tr td:first-child {
                padding-right: 20px;
            }
        }

    }

    .box_content ul {
        padding: 0 0 0 20px;
        margin: 20px 0 20px 0;
        font-size: 14px;
        line-height: 1.6em;
    }

    .box_content ul a, .box_content a {
        color: var(--secondaryColor);
    }

    .rateDetails > .box {
        padding: 10px 10px 10px 40px;
        background-position: 10px center;
        background-size: 20px auto;
        background-repeat: no-repeat;
    }

    #footerRateInfo {
        display: none;
    }

    form div.error {
        color: $color-error-font;
        font-size: 14px;
    }

    @include small-screens {
        div.rateDetails > .box > p > span {
            font-size: 12px;
        }
        div.rateDetails > .box > p > b {
            font-size: 12px;
        }

        .mobile_invisible {
            display: none;
        }
        .soft_alert p {
            margin-bottom: 50px;
        }

        .box_content {
            margin: 20px;
        }
        .box1 {
            width: 100%;
            height: auto;
            margin: 0 0 0 0;
        }
    }

    .t_professional a.tarif_details {
        color: $color-white;
    }

    @include big-screens {

        .footer {
            background: #ededed;
            margin: 0 auto 0 auto;
            padding: 0;
        }
        .box_content {
            margin: 20px;
        }
        .box1 {
            float: left;
            width: 50%;
        }
    }

    // forced because of external angular component
    .dropdown-list .arrow-up {
        display: none !important;
    }

    .selected-list .c-btn {
        padding-top: 14px !important;
        padding-bottom: 13px !important;
        border: 1px solid transparent !important;
        background: $color-lightgrey !important;
        box-shadow: none !important;
        font-size: 14px !important;
    }
}

.no-padding {
    padding: 0 !important;
}

.button {
    min-height: 38px;
    min-width: 160px;
    border-radius: 5px;
    cursor: pointer;

    span {
        text-align: center;
        padding: 0 5px;
        line-height: 38px;
        width: 100%;
        display: inline-block;

        i {
            font-size: larger;
            padding-right: 5px;
        }
    }

    &.outlined {
        border: solid 2px $color-white;
        border-radius: 5px;
        background: none;
        color: $color-white;
    }

    &.underlined {
        border: none;
        background: none;
        font-weight: bold;
        color: $color-white;
        text-decoration: underline;

        @include smaller-than(380px) {
            padding: 0 !important;
            margin: 0 !important;
        }
    }

    &.no-wrap {
        white-space: nowrap;
    }

    &.dark-blue {
        background-color: var(--mainColor);
        color: $color-white;
    }

    &[disabled] {
        background-color: $color-dark-grey;
        cursor: not-allowed;
    }
}

.flex {
    display: flex;

    &.center {
        justify-content: center;
    }
}

.no-display {
    display: none !important;
}

.hidden-on-small-screens {
    @include medium-screens {
        display: none;
    }
}

.numbers {
    font-family: 'Roboto', sans-serif;
}

.numbers-bold {
    font-family: 'Roboto', sans-serif;
    font-weight: bold;
}

.option-card {
    @include medium-drop-shadow;
    border-radius: 5px;
    overflow: hidden;
    width: 500px;
    max-width: 80%;
    margin: 250px auto;
    position: relative;

    @include medium-screens {
        max-width: 100%;
    }

    .option-card-body {
        background-color: white;
        min-height: 200px;
        padding: 20px;

        .option-card-heading {
            font-size: 1.5rem;
            font-weight: lighter;
            color: var(--mainColor);
            margin: 0;
        }

        .option-card-subtitle {
            color: $color-dark-grey;
            font-weight: normal;
            display: inline-block;
        }
    }

    .option-card-footer {
        background-color: $color-light-grey-rollover;
        min-height: 80px;
        padding: 20px;
    }
}

input[type="email"], input[type="text"], input[type="password"], .input-select, textarea {
    border: solid 1px var(--mainColor);
    border-left: solid 7px var(--mainColor);
}

.cursor-pointer {
    cursor: pointer;
}

.cursor-allowed {
    cursor: not-allowed;
}

.modal-dark {
    .modal-content {
        background-color: var(--mainColor) !important;
    }
}

.custom-modal {
    height: 420px;
    width: auto;
    top: 400px;
    left: 52%;

    @include medium-screens {
        left: 30%;
    }

    @include smaller-than(580px) {
        left: 15%;
    }

    @include smaller-than(440px) {
        left: 0;
    }

    .modal-content {
        opacity: 1;
        border-radius: 10px 10px 0px 0px;
        top: 0;
        left: 0;
        max-width: 450px;

        @include smaller-than(300px) {
            min-width: 300px;
        }

        @include small-screens {
            button {
                font-weight: 400;
            }
        }
    }

    .modal-dialog {
        margin: 5px auto;
    }
}

.custom-modal-shadow {
    background-color: transparent;
    height: 395px;
    width: 450px;
    top: 5px;
    left: 52%;

    @include medium-screens {
        left: 30%;
    }

    @include smaller-than(580px) {
        left: 15%;
    }

    @include smaller-than(440px) {
        left: 0;
    }
}

.custom-modal-overlay {
    background-color: transparent;
    height: 320px;
    width: 450px;
    top: 435px;
    left: 52%;

    @include medium-screens {
        left: 30%;
    }

    @include smaller-than(580px) {
        left: 15%;
    }

    @include smaller-than(440px) {
        left: 0;
    }
}

.deviceSetting-modal {
    background-color: transparent;
    width: 100%;
    top: 0;
    left: 0;
    border: 0;

    .modal-body {
        width: 100%;
        height: 100%;
    }

    .modal-content {
        background-color: transparent;
        border: 0;
        height: 590px;
        min-width: 350px;

        iframe {
            background-color: transparent;
            height: 100%;
            width: 100%;
        }
    }

    @include smaller-than(580px) {
        width: 85%;
        margin-left: 30px;
    }
}

.fileTransfer-modal {
    height: 420px;
    width: auto;
    top: 0;
    left: 52%;

    @include medium-screens {
        left: 30%;
    }

    @include smaller-than(580px) {
        left: 15%;

        .fileUploader {
            margin: 25px 80px;
        }
    }

    @include smaller-than(440px) {
        left: 0;
    }

    .modal-content {
        opacity: 1;
        border-radius: 10px 10px 0 0;
        top: 0;
        left: 0;
        max-width: 450px;

        @include smaller-than(410px) {
            min-width: 330px;

            .btn-send {
                margin-left: 0;
                width: 100px;
            }

            .fa.fa-upload {
                display: none;
            }

            .fileUploader {
                margin: 25px 95px;
            }

            .fileContainer {
                position: absolute;
            }
        }

        @include smaller-than(380px) {
            .fileUploader {
                margin: 25px 60px;
            }
        }

        @include smaller-than(330px) {
            min-width: 325px;
            .fileUploader {
                margin: 25px 50px;
            }
        }
    }

    .modal-dialog {
        margin: 5px auto;
    }

    .progress-bar {
        background-color: #00B8F1;
    }

    input[type="file"] {
        display: none;
    }

    .customFileUpload {
        background-color: #00B8F1;
        float: right;
        margin-left: 5px;
        font-weight: 600;
        color: white;
        border-color: transparent;
        cursor: pointer;
        padding: 0.4rem 0.85rem;
        outline: none;
        min-height: 30px;
        font-size: .875rem;
        line-height: 1.5;
        border-radius: .2rem;
        display: inline-block;
        text-align: center;
        white-space: nowrap;
        vertical-align: middle;
        user-select: none;
        transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    }
}

.modal-backdrop.show {
    opacity: .8;
}

.btn-link {
    cursor: pointer;
}

.bold {
    font-weight: bold !important;
}

.no-shadow {
    box-shadow: none !important;
}

.optional {
    font-size: 12px;
    font-weight: normal;
}

.float-right {
    float: right;
}

.float-left {
    float: left;
}

.link-disabled {
    pointer-events: none;
    opacity: 0.5;
}

.body-text-normal {
    font-size: 14px;
}

.link-white {
    color: var(--fontColorOnSecondaryColor);
    text-decoration: underline;

    &:hover {
        color: var(--mainColor);
    }
}

.color-blue {
    color: var(--mainColor);
}

.color-dark-blue {
    color: var(--mainColor);
}

.color-gray {
    color: $color-icon-grey;
}

.color-green {
    color: $color-green;
}

.color-red {
    color: $color-red;
}

.color-yellow {
    color: $color-yellow;
}

.color-black {
    color: $color-black;
}

.color-dark-gray {
    color: $color-dark-grey;
}

.color-alert {
    color: $color-alert;
}

.link-dark {
    color: black;
    text-decoration: underline;

    &:hover {
        color: var(--mainColor);
    }
}

// Chat-Tool
.siq_bR {
    bottom: 30px !important;
    right: 100px !important;
    border-radius: 50%;
    box-shadow: 0 15px 50px 2px rgba(0, 0, 0, .4);

    @include smaller-than(700px) {
        display: none !important;
    }
}

// userlane help tool
.userlane-base {
    @include smaller-than(700px) {
        display: none !important;
    }
}

.loading {
    text-align: center;
    font-size: 20px;
    padding: 20px;
    font-weight: 500;
    color: var(--mainColor);
}

ul[ngbnav] {
    background-color: #FFFFFF;
}

#term-tab-id-1,
#term-tab-id-2 {
    color: var(--mainColor);

    &.active {
        font-weight: 700;
        background-color: #ffffff;
    }

    .left-border {
        border-left: solid 1px black !important;
        padding-left: 15px;
    }

    .fal {
        padding-left: 10px;
    }
}

#progress-bar {
    width: 100%;
    height: 8px;
    background-color: var(--secondaryColor);

    .fill {
        background-color: $color-green;
        border-right: solid 3px white;
        height: 100%;
        min-width: 10%;
    }
}
iframe[id^='jitsiConferenceFrame'] {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: hidden;
    z-index: 101;
    background-color: $color-black;
}

.disable-ovs-scrollbars {
    height: 100%;
    overflow: hidden;
    width: 100%;
    position: fixed;
}
