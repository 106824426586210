@import "variables";
@import "~bootstrap/scss/mixins/breakpoints";

/*------------------------------------*\
  # Responsive
\*------------------------------------*/
/* TODO - Align with Bootstrap breakpoints */
$historic-breakpoint: 800px;
$medium-breakpoint: 1100px;

@mixin clearfix() {
    &::after {
        display: block;
        content: "";
        clear: both;
    }
}

@mixin small-screens {
    @media screen and (max-width: #{$historic-breakpoint - 1px}) {
        @content;
    }
}

@mixin medium-screens {
    @media screen and (max-width: #{$medium-breakpoint - 1px}) {
        @content;
    }
}

@mixin big-screens {
    @media screen and (min-width: #{$historic-breakpoint}) {
        @content;
    }
}

@mixin smaller-than($max-width) {
    @media screen and (max-width: #{$max-width}) {
        @content;
    }
}

@mixin bigger-than($max-width) {
    @media screen and (min-width: #{$max-width}) {
        @content;
    }
}

/*------------------------------------*\
  # Background opacity
\*------------------------------------*/
@mixin background-opacity($color, $opacity: 0.3) {
    background: $color; /* The Fallback */
    background: rgba($color, $opacity);
}

/*------------------------------------*\
  # Box shadow
\*------------------------------------*/
@mixin medium-drop-shadow() {
    box-shadow: 0 0 12px rgba(0, 0, 0, 0.3);
}

/*------------------------------------*\
  # Center placement
\*------------------------------------*/
@mixin absolute-center() {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

/*------------------------------------*\
  # legal
\*------------------------------------*/
@mixin legal-table() {
    table {
        border-spacing: 0;
        margin: 8px 0;
        display: block;
        overflow-x: auto;
    }

    tr, td, th {
        border: 1px solid $color-dark-grey;
        padding: 5px;
    }
}

@mixin legal-lists() {
    ol, ul {
        margin: 0 30px;
    }

  @media screen and (max-width: #{$medium-breakpoint - 1px}) {
    ol, ul {
      margin: 0 10px;
    };

  }
}

@mixin legal-text() {
    margin: 10px 0;
    font-size: small;
    color: $color-dark-grey;

    .description-list {
        padding-left: 40px;
    }
}

@mixin term-points {
    margin: 10px 0;
    padding-left: 20px;
}


/*------------------------------------*\
  # Spinner
\*------------------------------------*/
@mixin spinner-adjust-color($color) {
    .spinner-ring div {
        border-color: $color $color transparent transparent !important;
    }

    .spinner-ring div:nth-child(2) {
        border-color: transparent transparent $color $color !important;
    }
}

@mixin spinner($color) {
    .spinner-ring {
        position: relative;
    }

    .spinner-ring div {
        position: absolute;
        width: 205px;
        height: 205px;
        top: -2px;
        left: -2px;
        border-radius: 50%;
        border: 5px solid;
        border-color: $color $color transparent transparent;
        -webkit-animation: ring-animation 3.7s linear infinite;
        animation: ring-animation 3.7s linear infinite;
    }

    .spinner-ring div:nth-child(2) {
        width: 188px;
        height: 188px;
        top: 6px;
        left: 6px;
        border-color: transparent transparent $color $color;
        -webkit-animation: ring-animation 2.7s linear infinite;
        animation: ring-animation 2.4s linear infinite;
    }

    .spinner-ring {
        width: 200px !important;
        -webkit-transform: translate(-100px, -100px) scale(1) translate(100px, 100px);
        transform: translate(-100px, -100px) scale(1) translate(100px, 100px);
    }

    @include smaller-than(650px) {
        margin-left: auto;
        margin-right: auto;
    }

    @keyframes ring-animation {
        0% {
            -webkit-transform: rotate(0);
            transform: rotate(0);
        }
        100% {
            -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
        }
    }

    @-webkit-keyframes ring-animation {
        0% {
            -webkit-transform: rotate(0);
            transform: rotate(0);
        }
        100% {
            -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
        }
    }
}
