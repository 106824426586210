@import "variables";
@import "fonts";

.page-header {
    margin-top: 30px;
    margin-bottom: 10px;

    h1 {
        font-family: $font-family-raleway;
        color: $color-black-font;
        font-size: 2rem;
        margin: 0;
    }

    .sub-title {
        font-size: 14px;
        color: $color-black-font;
    }
}
