@import "fonts";
@import "variables";
@import "mixins";

$input-form-border-radius: 5px;
$default-input-height: 45px;

.form-group-header {
    h3 {
        font-family: $font-family-raleway;
        font-size: 20px;
        margin-bottom: 0;
        color: $color-black-font;
    }

    span {
        color: $color-dark-grey;
        font-size: 14px;
    }
}

.form-group {
    label {
        font-weight: 600;
        font-size: 14px;
    }
}

select {
    cursor: pointer;

    &.form-control {
        height: $default-input-height !important;
    }
}

.form-control {
    height: $default-input-height;
    font-size: 1rem;
    border-radius: $input-form-border-radius !important;

    &:focus {
        color: $color-black;
    }
}

.datepicker {
    #calendar-icon {
        position: absolute;
        cursor: pointer;
        z-index: 40;
        right: 10px;
        top: 15px;
        color: $color-dark-grey;
    }
}

.required {
    color: $color-red;
}

input[type="email"], input[type="text"], input[type="password"], input[type="number"], .input-select, .textarea, textarea {
    border: solid 1px var(--mainColor);
    border-left: solid 7px var(--mainColor);
    font-family: $font-family-roboto;
    font-weight: 500;
    color: $color-black-font !important;

    &:focus {
        outline: none;
        @include background-opacity($color-light-grey-footer, 0.5);
        border-color: var(--mainColor);
        box-shadow: none;
    }

    &.ng-invalid {
        &.ng-dirty, &.ng-touched {
            border-color: $color-red;
        }
    }

    &.ng-valid {
        &.ng-dirty, &.ng-touched {
            border-color: $color-green;
        }
    }

    &::placeholder {
        color: $color-placeholder-font !important;
        font-family: $font-family-roboto;
        font-weight: 200;
    }
}

label {
    margin: 5px 0 0 0;
    font-size: 0.8rem;
}

.ng-invalid + ngb-tooltip-window {
    .tooltip-inner {
        background-color: $color-red !important;
    }

    .arrow {
        &::before {
            border-bottom-color: $color-red !important;
        }
    }

    .error {
        border: $color-red 1px solid;
        border-left: $color-red 4px solid;

        .p {
            background: $color-red;
            font-size: 10px;
            color: $color-white;
        }
    }
}

.gray-multi-select-default {
    color: $color-black !important;

    .cuppa-dropdown {
        border: 1px solid var(--mainColor);
        border-left: 7px solid var(--mainColor);
        border-radius: 5px;

        .c-btn {
            box-shadow: none !important;
            border: none !important;
        }

        .c-token {
            background: $color-light-grey-footer !important;
            color: $color-black !important;

            .c-remove svg {
                fill: $color-black !important;
            }
        }

        .pure-checkbox label::before {
            border-color: $color-light-grey-footer !important;
        }

        .pure-checkbox input[type="checkbox"]:checked + label[_ngcontent-c1]:before {
            background: $color-light-grey-footer !important;
        }
    }
}

.gray-multi-select-untouched {
    color: $color-black !important;

    .cuppa-dropdown {
        border: 1px solid var(--mainColor);
        border-left: 7px solid var(--mainColor);
        border-radius: 5px;

        .c-btn {
            box-shadow: none !important;
            border: none !important;
        }

        .c-token {
            background: $color-light-grey-footer !important;
            color: $color-black !important;

            .c-remove svg {
                fill: $color-black !important;
            }
        }

        .pure-checkbox label::before {
            border-color: $color-light-grey-footer !important;
        }

        .pure-checkbox input[type="checkbox"]:checked + label[_ngcontent-c1]:before {
            background: $color-light-grey-footer !important;
        }
    }

}

.gray-multi-select-touched {
    color: $color-black !important;

    .cuppa-dropdown {
        border: 1px solid $color-green;
        border-left: 7px solid $color-green;
        border-radius: 5px;

        .c-btn {
            box-shadow: none !important;
            border: none !important;
        }

        .c-token {
            background: $color-light-grey-footer !important;
            color: $color-black !important;

            .c-remove svg {
                fill: $color-black !important;
            }
        }

        .pure-checkbox label::before {
            border-color: $color-light-grey-footer !important;
        }

        .pure-checkbox input[type="checkbox"]:checked + label[_ngcontent-c1]:before {
            background: $color-light-grey-footer !important;
        }
    }
}
